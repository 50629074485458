var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterSales',{on:{"filtersSale":_vm.filtersSale}})],1),_c('v-toolbar',{staticClass:"px-4 pt-3",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Pedidos Surtidos "),_c('br'),(_vm.filterStorage())?_c('span',{staticStyle:{"font-size":"12px","color":"grey","position":"absolute"}},[_vm._v("Mes Actual")]):_vm._e()]),_c('v-spacer'),(_vm.filterStorageLength== undefined)?_c('v-btn',{staticClass:"ml-6 pt-2",attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1):_c('v-btn',{staticClass:"ml-6",attrs:{"icon":""}},[_c('v-badge',{attrs:{"overlap":"","color":"primary"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.filterStorageLength)+" ")]},proxy:true}])},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1)],1)],1),(_vm.showTable)?_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, 100]},"headers":_vm.headers,"items":_vm.sales,"options":_vm.options,"server-items-length":_vm.totalSales,"loading":_vm.loading,"show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No existen registros de pedidos surtidos aún ")]},proxy:true},{key:"item.company_id",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/clients/client/'+ item.companyID}}},[_vm._v(_vm._s(item.company_id))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pb-4",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pa-6 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(item.items!=undefined)?_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f7f7f7"},attrs:{"hide-default-footer":"","items-per-page":item.items.length,"headers":_vm.headers2,"items":item.items,"dense":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-container',{staticStyle:{"max-width":"100%!important"}},[_c('a',{staticClass:"mx-3",staticStyle:{"text-decoration":"none"},attrs:{"href":'https://www.google.com.mx/maps/place/' + item.delivery_address + '/@',"target":"_blank"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-map-marker-outline")]),_c('span',[_vm._v(_vm._s(item.delivery_address))])],1)])]},proxy:true},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.name)+" ")]}},{key:"item.macro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.macro)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.price*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.price*item.quantity).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.totalWeight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.weight*item.quantity)+" kg ")]}}],null,true)}):_vm._e()],1),_c('v-col',{staticClass:"px-6",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('div',{staticClass:"px-4 py-3",staticStyle:{"background-color":"#ffedb3","border-radius":"0px 10px 10px 10px","min-height":"120px"}},[_c('strong',[_vm._v("Notas:")]),_c('br'),_c('span',[_vm._v(_vm._s(item.note))])])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[(item.pdf!=undefined)?_c('v-btn',{staticStyle:{"margin":"auto","display":"block"},attrs:{"href":_vm.liga + 'files/' + item.pdf,"target":"_blank","text":""}},[_vm._v(" PDF: "),_c('br'),_c('v-icon',[_vm._v("mdi-file-pdf-outline")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-btn',{staticClass:"mt-4",staticStyle:{"font-size":"12px","height":"30px!important","margin-left":"auto","margin-right":"auto","display":"block"},attrs:{"outlined":"","rounded":"","color":"red"},on:{"click":function($event){return _vm.rejection(item.id)}}},[_vm._v("Cancelar")])],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('printTicket')==true),expression:"permissions('printTicket')==true"}],on:{"click":function($event){return _vm.printTicket(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-printer ")]),_vm._v(" Imprimir ticket ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.addInvoice(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-document-outline ")]),_vm._v(" Facturar ")],1)],1)],1)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheetInvoice),callback:function ($$v) {_vm.sheetInvoice=$$v},expression:"sheetInvoice"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('v-row',{staticClass:"pt-6 px-12 mx-12"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Factura"},model:{value:(_vm.add_invoice.invoice),callback:function ($$v) {_vm.$set(_vm.add_invoice, "invoice", $$v)},expression:"add_invoice.invoice"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Fecha Factura","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.add_invoice.invoice_date),callback:function ($$v) {_vm.$set(_vm.add_invoice, "invoice_date", $$v)},expression:"add_invoice.invoice_date"}},on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.add_invoice.invoice_date),callback:function ($$v) {_vm.$set(_vm.add_invoice, "invoice_date", $$v)},expression:"add_invoice.invoice_date"}})],1)],1)],1),_c('v-btn',{attrs:{"disabled":_vm.add_invoice.invoice=='',"text":"","color":"primary"},on:{"click":function($event){return _vm.saveInvoice()}}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.sheetInvoice=false,_vm.add_invoice.invoice=''}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }