var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0 pb-8"},[_c('v-row',{staticClass:"pt-10 px-8 pb-12"},[_c('strong',[_vm._v("Filtros")]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"border":"solid 1px #dddddd"},attrs:{"small":""},on:{"click":function($event){return _vm.removeFilter()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-filter-off")]),_vm._v("Limpiar ")],1),_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.companyLists,"loading":_vm.isLoadingCompany,"search-input":_vm.searchCompanies,"hide-no-data":"","item-value":"id","item-text":"name","label":"Empresa(s)","placeholder":"Escribe para buscar","attach":"","chips":"","multiple":""},on:{"update:searchInput":function($event){_vm.searchCompanies=$event},"update:search-input":function($event){_vm.searchCompanies=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[(item.name!=null)?_c('v-list-item-title',[(item.macro!=null)?_c('span',[_vm._v(_vm._s(item.macro))]):_vm._e(),_vm._v(_vm._s(item.name)+" "),(item.razon_social!=null)?_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.razon_social))])]):_vm._e()]):(item.razon_social!=null)?_c('v-list-item-title',[_vm._v(" "+_vm._s(item.razon_social)+" ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.quotation.company_id),callback:function ($$v) {_vm.$set(_vm.quotation, "company_id", $$v)},expression:"quotation.company_id"}}),_c('v-autocomplete',{attrs:{"items":_vm.userList,"hide-no-data":"","item-value":"id","item-text":"name","label":"Venedor(es)","placeholder":"Escribe para buscar","attach":"","chips":"","multiple":""},model:{value:(_vm.quotation.user_id),callback:function ($$v) {_vm.$set(_vm.quotation, "user_id", $$v)},expression:"quotation.user_id"}}),_c('v-autocomplete',{attrs:{"items":_vm.userList,"hide-no-data":"","item-value":"id","item-text":"name","label":"Creador(es)","placeholder":"Escribe para buscar","attach":"","chips":"","multiple":""},model:{value:(_vm.quotation.created_by_user_id),callback:function ($$v) {_vm.$set(_vm.quotation, "created_by_user_id", $$v)},expression:"quotation.created_by_user_id"}}),_c('v-autocomplete',{attrs:{"items":_vm.userList,"hide-no-data":"","item-value":"id","item-text":"name","label":"Editor(es)","placeholder":"Escribe para buscar","attach":"","chips":"","multiple":""},model:{value:(_vm.quotation.last_updated_by_user_id),callback:function ($$v) {_vm.$set(_vm.quotation, "last_updated_by_user_id", $$v)},expression:"quotation.last_updated_by_user_id"}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeDatePicker(_vm.quotation.date)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"clearable":"","required":"","label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.quotation.date),callback:function ($$v) {_vm.$set(_vm.quotation, "date", $$v)},expression:"quotation.date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.quotation.date),callback:function ($$v) {_vm.$set(_vm.quotation, "date", $$v)},expression:"quotation.date"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeDatePicker(_vm.quotation.created_at)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"clearable":"","required":"","label":"Fecha Creación","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.quotation.created_at),callback:function ($$v) {_vm.$set(_vm.quotation, "created_at", $$v)},expression:"quotation.created_at"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.quotation.created_at),callback:function ($$v) {_vm.$set(_vm.quotation, "created_at", $$v)},expression:"quotation.created_at"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeDatePicker(_vm.quotation.updated_at)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"clearable":"","required":"","label":"Fecha Edición","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.quotation.updated_at),callback:function ($$v) {_vm.$set(_vm.quotation, "updated_at", $$v)},expression:"quotation.updated_at"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.quotation.updated_at),callback:function ($$v) {_vm.$set(_vm.quotation, "updated_at", $$v)},expression:"quotation.updated_at"}})],1),_c('v-text-field',{attrs:{"label":"Folio"},model:{value:(_vm.quotation.id),callback:function ($$v) {_vm.$set(_vm.quotation, "id", $$v)},expression:"quotation.id"}}),_c('v-text-field',{attrs:{"label":"Nota"},model:{value:(_vm.quotation.note),callback:function ($$v) {_vm.$set(_vm.quotation, "note", $$v)},expression:"quotation.note"}})],1),_c('v-card',{staticStyle:{"position":"absolute","bottom":"0","width":"100%"},attrs:{"tile":"","color":"primary"}},[_c('v-list-item',{attrs:{"link":"","dark":""},on:{"click":function($event){return _vm.filter()}}},[_c('v-list-item-content',[_c('strong',[_vm._v("Filtrar (0)")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }